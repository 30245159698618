import { useContext, useEffect, useState } from 'react';
import {
  useSWRFetcher,
  useSWRInfiniteFetcher
} from '../../../hooks/useStandardFetch';
import { Loading, LocalLoading } from '../../../components/Loading';
import userAvatar from '../../../assets/user-ph.png';
import { SenderMesssage } from './sender.message.components';
import SingleInput from '../../../components/SingleInput';
import { useForm } from 'react-hook-form';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { useDebounce } from '../../../hooks/useDebounce';
import { motion } from 'framer-motion';
import { ItemTestList } from './itemTestList.component';
import { TestDetail } from './test.details.component';
import { TechnicalProfile } from './technical.data.component';
import parse from 'html-react-parser';
import { ClubApproveProfileUser } from './club.approveProfileUser.component';
import { FilterByCoach } from './filterByCoach.component';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { BundleHistoryList } from './BundleHistoryList.component';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};


export function PlayersTab({ role, club: { UidClub, Role, ClubName }, clubApproveUser= null, setClubApproveUser= null,  showTechnicalProfile = null, setShowTechnicalProfile= null }) {

  //console.log(Role);
  const appContext = useContext(AppContext);
  const PAGE_SIZE = 5;
  const stdCall = useStandardCall();
  const [resource, setResourse] = useState(TheApp?.Res);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filteredCoach, setFilteredCoach] = useState({value: "all", label: "Tutti"});
  const [formVisble, setFormVisible] = useState({ show: false, player: null });
  const [detailsTest, setDetailsTest] = useState({
    show: false,
    test: null
  });
  const [detailsPlayer, setDetailsPlayer] = useState({});
  const [bundleHistory, setBundleHistory] = useState({
    show:false,
    player: null,
  })

  const [testList, setTestList] = useState({
    show: false,
    test: [],
    player: null
  });
  const [params, setParams] = useState({
    UidCoach: 'all'
  });
  const { data, mutate, size, setSize, isValidating, isLoading } =
    useSWRInfiniteFetcher(
      Role === 'CLUB'
        ? `app/club/players/${UidClub}?o=${params.Filters ? params.Filters : `fullname`
        }${params.Search ? `&s=${params.Search}` : ''}${params.UidCoach && params.UidCoach !== 'all'
          ? `&UidCoach=${params.UidCoach}`
          : ''
        }`
        : `app/players?UidClub=${UidClub}&o=${params.Filters ? params.Filters : `fullname`
        }${params.Search ? `&s=${params.Search}` : ''}${params.UidCoach && params.UidCoach !== 'all'
          ? `&UidCoach=${params.UidCoach}`
          : ''
        }`,
      PAGE_SIZE
    );

  const {
    data: coaches,
    loading: coachesLoading,
    error: errorCoaches
  } = useSWRFetcher(`app/club/coaches/${UidClub}`, Role !== "COACH" );
  
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({});
  const [players, setPlayers] = useState(data?.Items || []);

  function handlerBundleHistory(){
    setBundleHistory({show:true, player: clubApproveUser.player});
    setClubApproveUser({ show: false, player:null });
  }

  const inputsSender = [
    {
      id: 'Subject',
      label: 'Oggetto',
      type: 'text',
      placeholder: 'Oggetto messaggio',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-12 col-md-12'
    },
    {
      id: 'Content',
      label: 'Messaggio',
      type: 'textarea',
      placeholder: 'Insersci il messaggio da inviare',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-12 col-md-12'
    }
  ];
  const imputsFilter = [
    {
      id: 'Search',
      label: resource['Form.Search'] ?? "Cerca",
      type: 'text',
      defaultValue: params?.Search || '',
      placeholder: resource['Form.SearchFirstNameLastNamePlaceholder'] ?? "Cerca per nome, cognome",
      icon: 'fa fa-search',
      layout: 'col-lg-4 col-md-4'
    },
    {
      id: 'Filters',
      type: 'radioGroup',
      layout: 'col-lg-5 col-md-5',
      defaultValue: params?.Filters || 'fullname',
      inputs: [
        {
          id: 'fullname',
          label: resource['Form.FiltersLastName'] ?? "Alfabetico Cognome",
          group: 'Filters'
        },
        {
          id: 'lasttest',
          label: resource['Form.FiltersLastTest'] ?? "Ultimo Test",
          group: 'Filters'
        },
        {
          id: 'subscription',
          label: resource['Form.FiltersSubscriptionDate'] ?? "Data Iscrizione",
          group: 'Filters'
        }
      ]
    }
  ];
  useEffect(() => {
    mutate();
  }, [clubApproveUser, mutate]);
  // set messages as default on load
  useEffect(() => {
    if (data) {
      //flat pages data into one array
      const flatteredData = data.map((item) => item.Items).flat();
      setPlayers(flatteredData);
      setIsLoadingMore(data[data.length - 1].HasNextPage);
    }
  }, [data, params]);

  useEffect(() => {
    // updates select first value "all" to the select coach when value is available
    if(TheApp?.Res[TheApp.Locale]["Form.SelectAll"]){
      setFilteredCoach({value: "all", label: TheApp?.Res[TheApp.Locale]["Form.SelectAll"]})
    }
    //if the language has been changed, I update the resource
    setResourse(TheApp?.Res[TheApp.Locale]);
  }, [resource]);
  //mostro il form per inviare un messaggio
  function handlerShowForm(player) {
    setFormVisible((prev) => ({ show: !prev.show, player }));
  }
  //imposto la query per filtrare i giocatori
  const [intermediateQuery, setIntermediateQuery] = useState({});
  function handleFilterPlayer(data) {
    setIntermediateQuery({ ...intermediateQuery, ...data });
  }
  const searchQuery = useDebounce(intermediateQuery, 500);

  //set query for search players
  useEffect(() => {
    if (searchQuery) {
      const { Search, Filters } = searchQuery;
      appContext._setters._setLoading(true);
      setParams({ Search, Filters, UidCoach: filteredCoach.value });
      appContext._setters._setLoading(false);
    }
  }, [searchQuery, filteredCoach]);
  //show technical profile of the player
  function handlerShowTechProfile(player) {
    appContext._setters._setLoading(true);
    const call = stdCall.get(`app/profile?UidPlayer=${player}`, null, (rs) => {
      if (rs.Success) {
        setDetailsPlayer(rs.myProfile);
        setShowTechnicalProfile(true);
        appContext._setters._setLoading(false);
      }
      else{
        appContext._setters._setLoading(false);
      }
    });
  }
  //hide test list
  function handlerShowTestWrapper() {
    setTestList((prev) => ({ show: !prev.show, test: [], player: null }));
  }
  //show test list based on player clicked
  function handlerShowTestList(player) {
    appContext._setters._setLoading(true);
    const call = stdCall.get(
      `app/tests?uidPlayer=${player.UidPlayer}&uidClub=${UidClub}`,
      null,
      (rs) => {
        if (rs.Success) {
          setTestList((prev) => ({
            show: !prev.show,
            test: rs.TestsSessions.Items,
            player: player
          }));
          appContext._setters._setLoading(false);
        } else {
          // appContext._setters._setLoading(false);
          appContext._setters._setLoading(false);
          console.error(rs.Message);
        }
      }
    );
  }
  //show detail of the test
  function handlerSetDetailTest(test, role) {
    setDetailsTest((prev) => ({
      show: !prev.show,
      test: test,
      role
    }));
  }
  function handlerApproveProfileClub(player) {
    setClubApproveUser((prev) => ({ show: !prev.show, player }));
  }
  function handleFilerByCoach(data) {
    setFilteredCoach({
      value: data.value,
      label: data.label
    });
  }


  return (
    <div className="section-tab">
        <>
          {!formVisble.show &&
            !testList.show &&
            !showTechnicalProfile &&
            !clubApproveUser.show &&
            !bundleHistory.show && (
              <div className="row">
                <div className="col-lg-12">
                  <form
                    className="form-filter"
                    onChange={handleSubmit(handleFilterPlayer)}>
                    <div className="container">
                      <div className="row align-items-center">
                        {imputsFilter.map((input) => {
                          return (
                            <SingleInput
                              key={input.id}
                              errors={errors}
                              register={register}
                              control={control}
                              setValue={setValue}
                              {...input}
                            />
                          );
                        })}
                        {Role !== 'COACH' && coaches && (
                          <div className="wrapper-input col-lg-3 col-md-3 coaches-select-cont">
                            <div className="single-input">
                              <div className="col-lg-12">
                                <FilterByCoach
                                  coaches={coaches}
                                  defaultValue={filteredCoach}
                                  handleFilerByCoach={handleFilerByCoach}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
        </>
      {isLoading && <LocalLoading />}

      {data && players && players.length > 0 ? (
        <>
          {isLoading && (<Loading/>)}
          {formVisble.show ? (
            <SenderMesssage
              inputs={inputsSender}
              player={formVisble.player}
              handlerShowForm={handlerShowForm}
              UidClub={UidClub}
            />
          ) : (
            <>
              {testList.show && (
                <>
                  {testList.test?.length < 1 ? (
                    <div className="row comunications-wrapper xs-p-0">
                      <div
                        className="btn-close"
                        onClick={() => {
                          handlerShowTestWrapper((prev) => ({
                            show: !prev.show,
                            test: null
                          }));
                        }}>
                        <i className="fa-solid fa-circle-xmark"></i>
                      </div>
                      <div className="col-lg-12 xs-p-0">
                        <div className="wrapper-message to-appear alert-warning visible">
                          {resource && (
                            <p>
                              {parse(`${resource['Dashboard.NoTestMessage']}`)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <motion.div
                      variants={container}
                      initial="hidden"
                      animate="visible"
                      className="wrapper-tests xs-p-0">
                      {detailsTest.show ? (
                        <TestDetail
                          resetDetailTest={handlerSetDetailTest}
                          details={detailsTest}
                          player={testList.player}
                          role={Role}
                        />
                      ) : (
                        <motion.div
                          variants={item}
                          style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            paddingTop: '3rem'
                          }}>
                          <div className="container">
                            <div className="row">
                              <div
                                // variants={item}
                                className="col-lg-12 test-detail-info">
                                <h3 className="test-detail-title">
                                  {testList.player.FullName}
                                </h3>
                                {
                                  <ul className="personal-info">
                                    {testList.player.Height && (
                                      <li className="height">
                                        {testList.player.Height}{' '}
                                        {testList.player.HeightUnit}
                                      </li>
                                    )}
                                    {testList.player.Weight && (
                                      <li className="weight">
                                        {testList.player.Weight}{' '}
                                        {testList.player.WeightUnit}
                                      </li>
                                    )}
                                    {testList.player.Age && (
                                      <li className="age">
                                        {testList.player.Age} anni
                                      </li>
                                    )}
                                  </ul>
                                }
                              </div>
                            </div>
                          </div>
                          <div
                            className="btn-close"
                            onClick={() => {
                              handlerShowTestWrapper((prev) => ({
                                show: !prev.show,
                                test: null
                              }));
                            }}>
                            <i className="fa-solid fa-circle-xmark"></i>
                          </div>
                          {testList.test?.map((test) => {
                            return (
                              <ItemTestList
                                key={test.Uid}
                                test={test}
                                handlerSetDetailTest={handlerSetDetailTest}
                                ClubName={ClubName}
                                role={role}
                              />
                            );
                          })}
                        </motion.div>
                      )}
                    </motion.div>
                  )}
                </>
              )}
              {clubApproveUser.show && (
                <div style={{ padding: '2rem 1rem' }}>
                  <div
                    className="btn-close"
                    onClick={() => {
                      setClubApproveUser((prev) => ({
                        show: !prev.show,
                        player: null
                      }));
                    }}>
                    <i className="fa-solid fa-circle-xmark"></i>
                  </div>
                  <ClubApproveProfileUser
                    handlerBundleHistory={handlerBundleHistory}
                    club={UidClub}
                    player={clubApproveUser.player}
                  />
                </div>
              )}
              {
                bundleHistory.show && (
                  <div style={{ padding: '2rem 1rem' }}>
                  <div
                    className="btn-close"
                    onClick={() => {
                      setClubApproveUser((prev) => ({
                        show:true,
                        player: bundleHistory.player
                      }));
                      setBundleHistory((prev) => ({
                        show: !prev.show,
                        player:null,
                      }));
                    }}>
                    <i className="fa-solid fa-circle-xmark"></i>
                  </div>
                  <BundleHistoryList
                    player={bundleHistory.player}
                  />
                </div>
                )
              }
              {showTechnicalProfile && (
                <motion.div variants={container}>
                  <div
                    className="btn-close"
                    onClick={() => {
                      setShowTechnicalProfile(false);
                    }}>
                    <i className="fa-solid fa-circle-xmark"></i>
                  </div>
                  <div
                    className="container"
                    style={{ padding: '2rem' }}>
                    <div className="row">
                      <div className="col-lg-12 profile-detail-info">
                        <h3 className="txt-center profile-detail-title">
                          Profilo tecnico di:{' '}
                          <span>{detailsPlayer.FullName}</span>
                        </h3>
                      </div>
                    </div>
                    <TechnicalProfile
                      myProfile={detailsPlayer}
                      role={Role}
                    />
                  </div>
                </motion.div>
              )}
              {isValidating && <LocalLoading />}
              {!testList.show &&
                !showTechnicalProfile &&
                !clubApproveUser.show &&
                !bundleHistory.show &&(
                  <motion.div
                    className="wrapper-players container"
                    initial="hidden"
                    animate="visible"
                    variants={container}>
                    {players.map((player) => {
                      const lastTest = new Date(
                        player?.LastTestSessionDate
                      ).toLocaleString();
                      const DominantHand = player?.DominantHandLeft
                        ? 'Mancino'
                        : player?.DominantHandRight
                          ? 'Destro'
                          : null;
                      return (
                        <motion.div
                          variants={item}
                          className="row single-player"
                          key={player?.UidPlayer}>
                          <div
                            className="col-4 col-lg-2 col-sm-4 sm-2 xs-4"
                            style={{ position: 'relative' }}>
                            {player?.SubscriptionStatus === 0 && (
                              <div className="subscription-status-badge">
                                Da approvare
                              </div>
                            )}
                            {player?.SubscriptionStatus === 2 && (
                              <div className="subscription-status-badge suspended">
                                Da rinnovare
                              </div>
                            )}
                            <div className="wrapper-img">
                              <img
                                src={
                                  player?.ImageProfile
                                    ? `${TheApp.Env.BaseUrl}${player.ImageProfile}`
                                    : `${userAvatar}`
                                }
                                alt={player?.FullName}
                                className={player?.ImageProfile ? '' : 'no-img'}
                              />
                            </div>
                          </div>
                          <div className="col-9 col-lg-5 col-sm-6 sm-3 xs-8">
                            <div className="wrapper-info">
                              <h3>{player?.LastName} {player?.FirstName}</h3>
                              <h4 className="subscription-date">
                                Data iscrizione:{' '}
                                <strong>{player?.DateOfSubscription}</strong>
                              </h4>
                              <ul className="personal-info">
                                {player?.Height && (
                                  <li className="height">
                                    {player?.Height} {player?.HeightUnit}
                                  </li>
                                )}
                                {player?.Sex && (
                                  <li className="playerSex">
                                    {player?.Sex}
                                  </li>
                                )}
                                {player?.OneHandBackhand != null && (
                                  <li className="dominantHand">
                                    {
                                      player?.OneHandBackhand == false ? "rovescio bimane" : "rovescio ad una mano"
                                    }
                                  </li>
                                )}
                                {player?.Weight && (
                                  <li className="weight">
                                    {player?.Weight} {player?.WeightUnit}
                                  </li>
                                )}
                                {player?.Age && (
                                  <li className="age">{player?.Age} anni</li>
                                )}
                                {DominantHand && (
                                  <li className="dominantHand">
                                    {DominantHand}
                                  </li>
                                )}
                                <>
                                  {player?.Coach && (
                                    <li className="coachName">
                                      {player?.Coach}
                                    </li>
                                  )}
                                </>
                              </ul>
                            </div>
                          </div>
                          <div className="col-6 col-lg-3 col-sm-6 sm-3 xs-12 has-mt-xs-2">
                            <div className="test-info">
                              <h3 className="test-info-label">
                                <span>Test disponibili:</span>{' '}
                                {player?.TestAvailable || 0}
                              </h3>
                              <h3 className="test-info-label">
                                <span>Test effettuati:</span>{' '}
                                {player?.TestSessionCount || 0}
                              </h3>
                              <h3 className="test-info-label">
                                <span>Test Online:</span>{' '}
                                {player?.TestSessionOnlineCount || 0}
                              </h3>
                              {player?.TestSessionCount > 0 && (
                                <h4 className="test-info-date">
                                  Ultimo test: <span>{lastTest}</span>
                                </h4>
                              )}
                            </div>
                          </div>
                          <div className="col-6 col-lg-2 col-sm-6 sm-4 xs-12 has-no-pr-xs has-mt-xs-2">
                            <div className="player-action-btns">
                              <div
                                className="btn small secondary message"
                                onClick={() => {
                                  handlerShowForm(player.UidPlayer);
                                }}>
                                Invia messaggio
                              </div>
                              {Role !== 'CLUB' ?  (
                                <>
                                    <div
                                      className="btn small secondary test"
                                      onClick={() => {
                                        handlerShowTestList(player);
                                      }}>
                                      Vedi test
                                    </div>
                                  <div
                                    className="btn small secondary profile"
                                    onClick={() => {
                                      handlerShowTechProfile(player.UidPlayer);
                                    }}>
                                    <span>Vedi profilo tecnico</span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="btn small secondary profile"
                                    onClick={() => {
                                      handlerApproveProfileClub(
                                        player.UidPlayer
                                      );
                                    }}>
                                    Vedi dettaglio
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </motion.div>
                      );
                    })}
                    {isLoadingMore && (
                      <div className="offset-lg-4 col-lg-4">
                        <motion.div
                          variants={item}
                          className="btn primary"
                          style={{ marginBottom: '2rem' }}
                          onClick={() => setSize(size + 1)}>
                          Load more
                        </motion.div>
                      </div>
                    )}
                  </motion.div>
                )}
            </>
          )}
        </>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {!isLoading && (
                <div className="wrapper-message to-appear alert-warning visible">
                  <p>La ricerca non ha prodotto risultati</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
